<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1516_1440)">
      <path d="M3.38641 4.93359H0.25V14.9338H3.38641V4.93359Z" fill="currentColor"/>
      <path d="M11.8642 4.71065C11.7457 4.69764 11.6243 4.68825 11.5007 4.68319C9.74504 4.61166 8.75232 5.65352 8.40985 6.09786C8.31881 6.21708 8.27329 6.29366 8.27329 6.29366V4.95558H5.27344V14.9558H8.40985V10.2573C8.40985 9.57891 8.3571 8.85351 8.69668 8.23721C8.98424 7.71628 9.50373 7.45546 10.0918 7.45546C11.8237 7.45546 11.8649 9.02258 11.8649 9.17142V15.0013H15.0013V8.47853C15.0013 6.2467 13.867 4.92668 11.8649 4.71065H11.8642Z" fill="currentColor"/>
      <path d="M1.81855 3.63711C2.82291 3.63711 3.63711 2.82291 3.63711 1.81855C3.63711 0.814194 2.82291 0 1.81855 0C0.814194 0 0 0.814194 0 1.81855C0 2.82291 0.814194 3.63711 1.81855 3.63711Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_1516_1440">
        <rect width="15" height="15" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>